import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const MainApp = () => {
  return (
    <div className="App">
    <header className="App-header">
      <h1>bit&byte</h1>
      <p>
        email: info@bitandbyte.hr
      </p>
    </header>
   
  </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainApp />,
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
